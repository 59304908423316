import {licenseFee} from "./serviceData";

export const overallCostSavings = {
    daily: {cost: 2500, savings: 4},
    weekly: {cost: 7156, savings: 13},
    monthly: {cost: 19657, savings: 21},
    quarterly: {cost: 58765, savings: 29},
    yearly: {cost: 126748, savings: 43},
}
export const tmpCost = {
    daily: {cost: 17, savings: 4},
    weekly: {cost: 119, savings: 13},
    monthly: {cost: 476, savings: 21},
    quarterly: {cost: 1428, savings: 29},
    yearly: {cost: 5712, savings: 43},
}
export const tmpRoi = {
    daily: {cost: 76, perExecution: 4},
    weekly: {cost: 66, perExecution: 0.62},
    monthly: {cost: 71, perExecution: 21},
    quarterly: {cost: 72, perExecution: 29},
    yearly: {cost: 74, perExecution: 43},
}

export const tmpLicenseFee = {
    daily: {cost: 64, savings: 4},
    weekly: {cost: 448, savings: 13},
    monthly: {cost: 1899, savings: 21},
    quarterly: {cost: 5697, savings: 29},
    yearly: {cost: 22788, savings: 43},
}

export function getLicenseFees(licence: 'Basic' | 'Business' | 'Enterprise') {
    const monthly: number = licenseFee[licence];

    return {
        daily: {cost: (monthly / 31), savings: 4},
        weekly: {cost: (monthly / 4), savings: 13},
        monthly: {cost: monthly, savings: 21},
        quarterly: {cost: (monthly * 4), savings: 29},
        yearly: {cost: (monthly * 12), savings: 43},
    }
}

export const tmpCreditPackage = {
    Small: {used: 100, total: 5000},
    XLarge: {used: 12500, total: 50000}
}

export const tmpOverallCostsYearly = [{
    label: 'Licence Fee',
    value: 22788,
    percent: 56
}, {
    label: 'Credits',
    value: 25000,
    percent: 44
}]

export const tmpMonthlySavings = [{
    month: 'JAN',
    cost: 110,
    saving: 1120
},{
    month: 'FEB',
    cost: 210,
    saving: 1020
},{
    month: 'MAR',
    cost: 310,
    saving: 1100
},{
    month: 'APR',
    cost: 410,
    saving: 1320
},{
    month: 'MAI',
    cost: 330,
    saving: 1330
},{
    month: 'JUN',
    cost: 190,
    saving: 1290
},{
    month: 'JUL',
    cost: 154,
    saving: 934
},{
    month: 'AUG',
    cost: 201,
    saving: 901
},{
    month: 'SEP',
    cost: 212,
    saving: 932
},{
    month: 'OCT',
    cost: 312,
    saving: 1820
},{
    month: 'NOV',
    cost: 342,
    saving: 1956
},{
    month: 'DEZ',
    cost: 310,
    saving: 1820
}]

export const tmpMonthlyCosts = [{
    month: 'JAN',
    licenceFee: 799,
    creditsPackage: 2000
},{
    month: 'FEB',
    licenceFee: 1899,
    creditsPackage: 10000
},{
    month: 'MAR',
    licenceFee: 1899,
    creditsPackage: 0
},{
    month: 'APR',
    licenceFee: 1899,
    creditsPackage: 0
},{
    month: 'MAI',
    licenceFee: 1899,
    creditsPackage: 10000
},{
    month: 'JUN',
    licenceFee: 1899,
    creditsPackage: 0
},{
    month: 'JUL',
    licenceFee: 1899,
    creditsPackage: 15000
},{
    month: 'AUG',
    licenceFee: 1899,
    creditsPackage: 0
},{
    month: 'SEP',
    licenceFee: 1899,
    creditsPackage: 0
},{
    month: 'OCT',
    licenceFee: 2499,
    creditsPackage: 15000
},{
    month: 'NOV',
    licenceFee: 2499,
    creditsPackage: 0
},{
    month: 'DEZ',
    licenceFee: 2499,
    creditsPackage: 2000
}];


export const tmpApplicationSavings = [{
    label: 'Categorizer',
    value: 11000,
    savings: 10000,
    percent: 21
}, {
    label: 'Translation',
    value: 10000,
    savings: 5000,
    percent: 10
}, {
    label: 'NLU',
    value: 10000,
    savings: 5000,
    percent: 10
}]

export const tmpEmailLabelServicesOverview = [{
    label: 'Categorizer',
    value: 125,
    share: 37,
    costs: 220,
    savings: 450,
    percent: 46
}, {
    label: 'Summarizer',
    value: 125,
    share: 37,
    costs: 220,
    savings: 450,
    percent: 46
}, {
    label: 'Translation',
    value: 125,
    share: 37,
    costs: 220,
    savings: 450,
    percent: 46
}]

// ---------------------------------------------------------------------------------------------------------
export const container: IContainer[] = [{
    label: 'Translation Service',
    tags: ['CAT'],
    price: 3,
    runs: 200
}, {
    label: 'Summary Service',
    tags: ['CAT'],
    price: 0,
    runs: 100
}, {
    label: 'Text Classifier Service',
    tags: ['CAT'],
    price: 1,
    runs: 100
}, {
    label: 'Indexer Service',
    tags: ['TSIM'],
    price: 6,
    runs: 100
}, {
    label: 'MinIO',
    tags: ['TSIM'],
    price: 0,
    runs: 100
}, {
    label: 'Elasticsearch',
    tags: ['TSIM', 'SESE'],
    price: 0,
    runs: 250
}, {
    label: 'Searcher Service',
    tags: ['TSIM', 'SESE'],
    price: 4,
    runs: 150
}, {
    label: 'Data Storage',
    tags: [],
    price: 0,
    runs: 100
}, {
    label: 'Some UI',
    tags: [],
    price: 0,
    runs: 1000
}];

export interface IContainer {
    label: string
    tags: string[]
    price: number
    runs: number
}
