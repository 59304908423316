import React from 'react';

import ExtensionsCards from './ExtensionsCards';

import AuthenticatedLayout from "../../layout/AuthenticatedLayout";

import TagFilter from "../../filter/TagFilter";

export default function ExtensionView(props: IProps) {
    return (
            <AuthenticatedLayout title={props.pageTitle}
                                 breadcrumbs={[]}
                                 size="xl">
                <TagFilter/>
                <ExtensionsCards/>
            </AuthenticatedLayout>
    )
}

interface IProps {
    pageTitle: string
}
