import {IContainer} from "../components/view/overviews/dashboard/costs/dummyData";

// TODO: pass services from backend
export function extractAllLabels(services: any): string[] {
    let tmp: string[] = [];
    services.forEach((s: IContainer) => {
        s.tags.forEach(l => {
            if (!tmp.includes(l)) {
                tmp.push(l);
            }
        })
    });

    return tmp;
}