import React from 'react';

import {Grid, Paper, styled} from "@mui/material";

const Card = styled(Paper)(({theme}: any) => ({
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: 300,
    width: 300,
    lineHeight: '60px',
    '&:hover': {
        cursor: 'pointer',
        backgroundColor: '#23252cAA',
        transition: 'all 0.2s'
    }
}));

export default function ExtensionsCards() {
    return (
            <Grid container xs={12} spacing={2}>
                <Grid item>
                    <Card>
                        XAI
                    </Card>
                </Grid>
                <Grid item>
                    <Card>
                        Continuous Learning
                    </Card>
                </Grid>
                <Grid item>
                    <Card>
                        Lucy
                    </Card>
                </Grid>
            </Grid>
    )
}
