import React from "react";
import ExtensionsView from "../components/view/extensions/ExtensionsView";

import {isBrowser} from "../misc/misc";
import {ThemeProvider as MuiThemeProvider} from "@mui/material/styles";
import createTheme from "../styles/theme";
import {THEMES} from "../styles/theme/constants";

export default function ExtensionsPage() {
    if (!isBrowser) return null // needed for build

    return (
        <MuiThemeProvider theme={createTheme(THEMES.DARK)}>
            <ExtensionsView pageTitle={'Extensions'}/>
        </MuiThemeProvider>
    )
}
