export const licenseFee = {
    'Basic': 799,
    'Business': 1899,
    'Enterprise': 2499
}

export const creditPackages = {
    'Small': {
        total: 10000,
        cost: 2000,
        creditPrice: 0.20
    },
    'Medium': {
        total: 25000,
        cost: 3750,
        creditPrice: 0.15
    },
    'Large': {
        total: 100000,
        cost: 10000,
        creditPrice: 0.10
    },
    'XLarge': {
        total: 500000,
        cost: 15000,
        creditPrice: 0.03
    },
}

export const services = [{
    name: 'Categorizer',
    labels: ['Email'],
    runs: 1000
}, {
    name: 'Translation',
    labels: ['Email', 'Tickets'],
    runs: 2000
}, {
    name: 'NLU',
    labels: ['Email', 'App3'],
    runs: 2500
}, {
    name: 'Similar',
    labels: ['Tickets'],
    runs: 800
}, {
    name: 'Summarizer',
    labels: ['Tickets', 'App3'],
    runs: 200
}];

export const tenantData: ITenantData[] = [{
    tenantName: 'MCD',
    services: services,
    licence: 'Business',
    credits: {
        package: 'XLarge',
        used: 246980
    }
}, {
    tenantName: 'Merkur',
    services: services,
    licence: 'Basic',
    credits: {
        package: 'Medium',
        used: 2341
    }
}];

export interface ITenantData {
    tenantName: string
    services: any
    licence: 'Basic' | 'Business' | 'Enterprise'
    credits: ICredit
}

export interface ICredit {
    package: 'Small' | 'Medium' | 'Large' | 'XLarge'
    used: number
}
