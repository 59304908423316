import {createContext} from "react";

export const TagFilterContext = createContext<ITagFilterContext>({
    filteredLabels: [],
    setFilteredLabels: null
});

interface ITagFilterContext {
    filteredLabels: string[]
    setFilteredLabels: any
}
