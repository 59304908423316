import React, {useContext} from 'react';

import {Box, Divider, styled} from "@mui/material";

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import {TagFilterContext} from "../view/overviews/tagFilter/TagFilter";
import {extractAllLabels} from "../../misc/labels";

import {container} from "../view/overviews/dashboard/costs/dummyData";

const StickyLabelFilter = styled(Box)(({theme}) => ({
    marginBottom: '10px',
    width: '100%',
    position: 'sticky',
    top: '50px',
    backgroundColor: theme.palette.background.default,
    zIndex: 9
}));

export default function TagFilter() {
  const {setFilteredLabels} = useContext(TagFilterContext);

  function handleOnChange(values: string[]) {
      setFilteredLabels(values);
  }

  return (
    <StickyLabelFilter>
      <Autocomplete
                disablePortal
                multiple
                id="combo-box-demo"
                options={extractAllLabels(container)}
                sx={{ width: 'fit-content', minWidth: '400px' }}
                renderInput={(params) => (
                        <TextField
                                {...params}
                                variant="standard"
                                label="Tags"
                        />
                )}
                onChange={(e, v) => handleOnChange(v)}
      />
      <Divider sx={{width: '100%', margin: '20px 0'}}/>
    </StickyLabelFilter>
  )
}
